* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
  color: black;
}


@media screen and (max-width: 420px) {
  .map-hidden-overflow {
    height: 100vh;
    overflow: hidden;
  }

  .gallery .gallery-photo.gallery-photo {
    height: 70vh;
  }
}
